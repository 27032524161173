import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
    stores: null,
    filteredStores: null,
    closestStore: null,
    visibleStores: null,
});

export default function storesReducer(state = initState, action) {
    switch (action.type) {
        case actions.SET_FILTERED_STORES:
            return state.set('filteredStores', action.payload);
        case actions.SET_VISIBLE_STORES:
            return state.set('visibleStores', action.payload);
        case actions.SET_STORES_BY_MERCHANT_ID:
            return state.set('stores', action.payload);
        case actions.SET_CLOSEST_STORE:
            return state.set('closestStore', action.payload);
        default:
            return state;
    }
}
