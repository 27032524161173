import { get, isArray, isEmpty } from 'lodash';
import { LegacyDelayedOrdersUtils } from '@goparrot/core-sdk/platform/store-v2/module/store/utils/legacy/legacy-delayed-orders.utils';
import { ReceiveMethodEnum } from '@goparrot/core-sdk/platform/store-v2/module/store/interface/delayed-order.interface';
import qs from 'qs';

export const getMiles = (i) => (i * 0.000621371192).toFixed(1);

export function closeWebview(error) {
    try {
        /* eslint-disable */
        if (window.MessengerExtensions) {
            window.MessengerExtensions.requestCloseBrowser(
                function success() {
                    console.log('webview closed');
                    if (error) console.error(error);
                },
                function error(err) {
                    console.log(err);
                },
            );
        } else {
            window.close();
        }
    } catch (err) {
        window && window.close();
    }
}

export const getOrderAheadMessage = (store, data) => {
    if (!store.humanReadableData.isOpen) {
        const orderAheadAvailabilityForMethods = LegacyDelayedOrdersUtils.isOrderAheadAvailable(store.details);
        if (
            (store.details.isPickUp && orderAheadAvailabilityForMethods[ReceiveMethodEnum.PICKUP]) ||
            (store.details.isDelivery && orderAheadAvailabilityForMethods[ReceiveMethodEnum.DELIVERY])
        ) {
            return get(data, 'orderAheadMessage.message', 'Order Ahead is available.');
        }
    }
};
/* eslint-enable */
export const getStoreStatus = ({ isActive, details, humanReadableData }, buttonText = {}) => {
    if (!isActive || !details.isTakingOrders) {
        return buttonText.viewMenu || 'View Menu';
    }
    if (!humanReadableData.isOpen) {
        return 'never' !== humanReadableData.openingTime
            ? `${buttonText.opensPrefix || 'Opens'} ${humanReadableData.openingTime}`
            : buttonText.opensNever || 'Opens soon';
    }
    return buttonText.orderNow || 'Order now';
};

export const hideStoreField = (placePickerStore, field) => {
    return get(placePickerStore, `popup.hide.${field}`, false);
};

/**
 * Filters stores based on
 * @param {*} props
 */
export function getVisibleLocationForSelectedLocation({ selectedLocation, filteredStores = [], merchantStore, useDeliveryAlgorithm, bounds, isStaticMap }) {
    if (selectedLocation && selectedLocation.viewport && usesDeliveryAlgorithm(merchantStore.details, useDeliveryAlgorithm)) {
        return filteredStores.filter((store) => !!store.deliveryRadiusContainsLocation);
    } else if (selectedLocation && selectedLocation.viewport && !usesDeliveryAlgorithm(merchantStore.details, useDeliveryAlgorithm)) {
        return filteredStores.filter((store) => Boolean(store.location));
    } else {
        return filteredStores
            .filter((store) => Boolean(store.location))
            .filter((store) => {
                return (
                    bounds &&
                    bounds.contains({
                        lat: store.location.latitude,
                        lng: store.location.longitude,
                    })
                );
            });
    }
}

const isDeliveryOnlyMerchant = (details) => {
    if (!details) return false;
    const { isPickUp, isDelivery } = details;
    return isDelivery && !isPickUp;
};

/**
 * @param {MerchantStore} details
 * returns true if delivery radius search Algorithm will be applied
 */
export const usesDeliveryAlgorithm = (details, useDeliveryAlgorithm) => {
    const { dpdefault } = qs.parse(window.location.search);
    if (useDeliveryAlgorithm) return true;

    if (dpdefault === undefined) return isDeliveryOnlyMerchant(details);

    return ReceiveMethodEnum.DELIVERY === dpdefault;
};

export function hasDeliveryRadiusData(storeDetails) {
    return storeDetails.isDelivery && isArray(storeDetails.deliveryRadius) && storeDetails.deliveryRadius[0] && !isEmpty(storeDetails.deliveryRadius[0].data);
}

export function hasDeliveryRadiusDataPoly(storeDetails) {
    return hasDeliveryRadiusData(storeDetails) && isArray(storeDetails.deliveryRadius[0].data);
}

export function hasDeliveryRadiusDataSphere(storeDetails) {
    return hasDeliveryRadiusData(storeDetails) && storeDetails.deliveryRadius[0].data.radius && !isEmpty(storeDetails.deliveryRadius[0].data.center);
}

/**
 * Takes all filtered stores and tries to calculte distance to selectedLocation
 * In case of delivery algorith it marks if store can delivery to selectedLocation
 * If there is no selectedLocation, then it tries to calculate distance from sortingCenter if it's available
 */
export function onLocationChangeRecalculate({ filteredStores, selectedLocation, mapDetails }) {
    const { google } = window;
    if (selectedLocation) {
        const location = new google.maps.LatLng(selectedLocation.location);

        return filteredStores.map((store) => {
            const { latitude: lat, longitude: lng } = store.details.restaurantAddress;
            const storeAddress = new google.maps.LatLng({ lat, lng });
            if (hasDeliveryRadiusDataPoly(store.details)) {
                store.deliveryRadiusContainsLocation = google.maps.geometry.poly.containsLocation(
                    location,
                    new google.maps.Polygon({ paths: store.details.deliveryRadius[0].data.map(([lat, lng]) => ({ lat, lng })) }),
                );
            }

            if (hasDeliveryRadiusDataSphere(store.details)) {
                store.deliveryRadiusContainsLocation = new google.maps.Circle({
                    center: new google.maps.LatLng(store.details.deliveryRadius[0].data.center[0], store.details.deliveryRadius[0].data.center[1]),
                    radius: store.details.deliveryRadius[0].data.radius,
                })
                    .getBounds()
                    .contains({ lat: location.lat(), lng: location.lng() });
            }

            store.distance =
                null === selectedLocation.location ? null : getMiles(google.maps.geometry.spherical.computeDistanceBetween(location, storeAddress));

            return store;
        });
    } else if (!selectedLocation && mapDetails && mapDetails.sortingCenter) {
        const center = mapDetails.sortingCenter;
        const location = new google.maps.LatLng(center);

        return filteredStores.map((store) => {
            const { latitude: lat, longitude: lng } = store.details.restaurantAddress;
            const storeAddress = new google.maps.LatLng({ lat, lng });
            store.distance = getMiles(google.maps.geometry.spherical.computeDistanceBetween(location, storeAddress));

            return store;
        });
    } else {
        return filteredStores.map((store) => {
            store.distance = undefined;
            return store;
        });
    }
}

export const getClosestLocation = ({ filteredStores, map }) => {
    const { google } = window;
    const location = map.getCenter();

    return filteredStores
        .map((store) => {
            const { latitude: lat, longitude: lng } = store.details.restaurantAddress;
            const storeAddress = new google.maps.LatLng({ lat, lng });
            store.distance = getMiles(google.maps.geometry.spherical.computeDistanceBetween(location, storeAddress));
            return store;
        })
        .sort((storeA, storeB) => storeA.distance - storeB.distance);
};
