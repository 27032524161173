const constants = {
    SET_MERCHANT_BY_URL: 'SET_MERCHANT_BY_URL',
    GET_MERCHANT_BY_URL: 'GET_MERCHANT_BY_URL',
    GET_TEMPLATE_BY_MERCHANT_ID: 'GET_TEMPLATE_BY_MERCHANT_ID',
    SET_TEMPLATE_BY_MERCHANT_ID: 'SET_TEMPLATE_BY_MERCHANT_ID',
    GET_API_KEYS: 'GET_API_KEY',
    SET_API_KEYS: 'SET_API_KEY',
    SET_DELIVERY_ALGHORITHM: 'SET_DELIVERY_ALGHORITHM',
};

const actions = {
    getApiKeys: () => ({
        type: constants.GET_API_KEYS,
    }),
    getStoresByMerchantId: (payload) => ({
        type: constants.GET_MERCHANT_BY_URL,
        payload,
    }),
    getTemplateByMerchantId: (payload) => ({
        type: constants.GET_TEMPLATE_BY_MERCHANT_ID,
        payload,
    }),
    setDeliveryAlghorithm: (payload) => ({
        type: constants.SET_DELIVERY_ALGHORITHM,
        payload,
    }),
};

export default { ...actions, ...constants };
