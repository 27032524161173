import styled from 'styled-components';

export const MapContainer = styled.div`
    height: calc(100vh - ${({ theme }) => theme.header.height});
`;

export const Banner = styled.div`
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: calc(100vh - ${({ theme }) => theme.header.height});
    background-color: ${({ theme }) => theme.banner.backgroundColor.desktop};
    background-image: url(${({ theme }) => theme.banner.imgUrl.desktop});
    background-repeat: no-repeat;
    background-size: contain;
    background-position: ${({ theme }) => theme.banner.imgPosition.desktop};
`;

export const BannerMobile = styled.div`
    position: absolute;
    z-index: 9999;
    width: 100%;
    height: 200px;
    background-color: ${({ theme }) => theme.banner.backgroundColor.mobile};
    background-image: url(${({ theme }) => theme.banner.imgUrl.mobile});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: ${({ theme }) => theme.banner.imgPosition.mobile};
`;
