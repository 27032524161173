const constants = {
    GET_PLACEPICKER_STORES_REQUEST: 'GET_PLACEPICKER_STORES_REQUEST',
    GET_PLACEPICKER_STORES_SUCCESS: 'GET_PLACEPICKER_STORES_SUCCESS',
    GET_PLACEPICKER_STORES_ERROR: 'GET_PLACEPICKER_STORES_ERROR'
};

const actions = {
    requestGetPlacePickerStore: payload => ({
        type: constants.GET_PLACEPICKER_STORES_REQUEST,
        payload
    }),
    successGetPlacePickerStore: payload => ({
        type: constants.GET_PLACEPICKER_STORES_SUCCESS,
        payload
    }),
    errorGetPlacePickerStore: payload => ({
        type: constants.GET_PLACEPICKER_STORES_ERROR,
        payload
    })
};

export default { ...constants, ...actions };
