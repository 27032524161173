import React from 'react';

const HeaderLogo = ({ headerLogo, merchantStore }) => (
    <React.Fragment>
        {headerLogo && headerLogo.url ? (
            <a href={headerLogo.url} rel="noopener noreferrer" target={headerLogo.openInNewTab ? '_blank' : '_parent'}>
                <img src={merchantStore ? headerLogo.image : ''} alt="" />
            </a>
        ) : (
            <img src={merchantStore && headerLogo ? headerLogo.image : ''} alt="" />
        )}
    </React.Fragment>
);

export default HeaderLogo;
