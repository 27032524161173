import { all, takeEvery, call, fork } from 'redux-saga/effects';
import actions from './actions';
import { WebstoreFacade } from '../../facade/webstore.facade';

export function* setSelectedLocation() {
    yield takeEvery(actions.SET_SELECTED_LOCATION, function*({ payload }) {
        yield call(WebstoreFacade.getWebstoreUrls, payload);
    });
}

export default function* rootSaga() {
    yield all([fork(setSelectedLocation)]);
}
