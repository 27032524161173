import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
    stores: null,
    error: null
});

export default function placePickerStoreReducer(state = initState, action) {
    switch (action.type) {
        case actions.GET_PLACEPICKER_STORES_SUCCESS:
            return state.set('stores', action.payload);
        case actions.GET_PLACEPICKER_STORES_ERROR:
            return state.set('error', action.payload);
        default:
            return state;
    }
}
