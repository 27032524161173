import { Layout } from 'antd';
import styled from 'styled-components';

export default styled(Layout)`
    padding: 0 0 24px;
    background: whitesmoke;
    margin-${({ theme }) => theme.position.sidebar}: 550px;
    @media (max-width: 992px) {
        margin-${({ theme }) => theme.position.sidebar}: 350px;
    }
`;
