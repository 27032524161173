import { Layout } from 'antd';
import styled from 'styled-components';

export default styled(Layout.Sider)`
    background: whitesmoke;
    height: 100vh;
    overflow: auto;
    position: fixed;
    ${({ theme }) => theme.position.sidebar}: 0;
`;
