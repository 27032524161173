import { all, takeEvery, call, fork, put } from 'redux-saga/effects';
import actions from './actions';
import { MerchantFacade } from '../../facade/merchant.facade';

export function* getMerchantByUrl() {
    yield takeEvery(actions.GET_MERCHANT_BY_URL, function*({ payload }) {
        const result = yield call(MerchantFacade.getMerchantByUrl, payload);
        if (!result || result.status === 'ERROR') {
            return false;
        }
        yield put({
            type: actions.SET_MERCHANT_BY_URL,
            payload: result.value
        });
    });
}

export function* getTemplateByMerchantId() {
    yield takeEvery(actions.GET_TEMPLATE_BY_MERCHANT_ID, function*({ payload }) {
        const result = yield call(MerchantFacade.getTemplateByMerchantId, payload);
        if (!result || result.status === 'ERROR') {
            return false;
        }
        const { value } = result;
        yield put({
            type: actions.SET_TEMPLATE_BY_MERCHANT_ID,
            payload: (value && value.template) || {}
        });
    });
}

export function* getApiKeys() {
    yield takeEvery(actions.GET_API_KEYS, function*() {
        const result = yield call(MerchantFacade.getApiKeys);
        yield put({ type: actions.SET_API_KEYS, payload: result.value });
    });
}

export default function* rootSaga() {
    yield all([fork(getMerchantByUrl), fork(getTemplateByMerchantId), fork(getApiKeys)]);
}
