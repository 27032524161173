import { get } from 'lodash';
import { all, takeEvery, call, fork, put, select } from 'redux-saga/effects';
import qs from 'qs';
import actions from './actions';
import { StoreFacade } from '../../facade/store.facade';
import { getPlacePickerStores } from './selectors';

export function* getStoresByMerchantId() {
    yield takeEvery(actions.GET_STORES_BY_MERCHANT_ID, function*({ payload }) {
        const result = yield call(StoreFacade.getStoresByMerchantId, payload);
        if (!result || 'ERROR' === result.status) {
            return false;
        }

        const placePickerStores = yield select(getPlacePickerStores) || [];

        let stores = result.value.filter((store) => {
            const placePickerStore = placePickerStores && placePickerStores.find((placePickerStore) => placePickerStore.storeId === store.storeId);
            return get(placePickerStore, 'enabled', true);
        });

        if (payload.tags) {
            // workaround to prevent qs returning array with 1 empty string when no filters are in query
            const getQueryString = qs.parse(payload.tags, { ignoreQueryPrefix: true });
            const storesFilter = getQueryString.tags ? getQueryString.tags.filter(Boolean) : null;

            if (storesFilter && storesFilter.length) {
                stores = stores.filter((store) => {
                    const placePickerStore = placePickerStores && placePickerStores.find((placePickerStore) => placePickerStore.storeId === store.storeId);
                    if (placePickerStore && placePickerStore.tags.length) {
                        const tags = placePickerStore.tags.map((tag) => tag.toLowerCase());
                        return storesFilter.every((tag) => tags.includes(tag));
                    }
                    return false;
                });
            }
        }

        yield put({
            type: actions.SET_STORES_BY_MERCHANT_ID,
            payload: stores,
        });
        yield put({
            type: actions.SET_FILTERED_STORES,
            payload: stores,
        });
    });
}

export default function* rootSaga() {
    yield all([fork(getStoresByMerchantId)]);
}
