import axios from 'axios/index';
import { closeWebview } from '../helpers/utils';

const { REACT_APP_SERVER } = process.env;

export class WebstoreFacade {
    static async getWebstoreUrls(payload) {
        return axios
            .get(`${REACT_APP_SERVER}/api/v2/web-stores/${payload.storeId}/urls`)
            .then((res) => {
                closeWebview();
                // eslint-disable-next-line
                window.parent.postMessage(
                    {
                        redirectUrl: res.data.value,
                    },
                    '*',
                );
            })
            .catch((err) => console.log(err));
    }
}
