import * as React from 'react';
import { Spin, Icon } from 'antd';
import 'antd/es/spin/style/css';

const antIcon = <Icon type="loading" style={{ fontSize: 24 }} spin />;

export default class Spinner extends React.Component {
    render() {
        return (
            <React.Fragment>
                {this.props.error ? 'Error! Please refresh the page' : ''}
                {this.props.isLoading ? <Spin indicator={antIcon} /> : ''}
                {this.props.pastDelay}
                {this.props.timedOut ? 'Taking a long time...' : ''}
            </React.Fragment>
        );
    }
}
