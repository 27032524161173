import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
    merchantStore: null,
    template: null,
    gTag: null,
    useDeliveryAlgorithm: false,
});

export default function merchantReducer(state = initState, action) {
    switch (action.type) {
        case actions.SET_MERCHANT_BY_URL:
            return state.set('merchantStore', action.payload);
        case actions.SET_TEMPLATE_BY_MERCHANT_ID:
            return state.set('template', action.payload);
        case actions.SET_API_KEYS:
            return state.set('gTag', action.payload.gTag);
        case actions.SET_DELIVERY_ALGHORITHM:
            return state.set('useDeliveryAlgorithm', action.payload);
        default:
            return state;
    }
}
