import { all, takeEvery, call, fork, put } from 'redux-saga/effects';
import actions from './actions';
import { PlacePickerStoreFacade } from '../../facade/place-picker-store.facade';

export function* getPlacePickerStore() {
    try {
        yield takeEvery(actions.GET_PLACEPICKER_STORES_REQUEST, function*({ payload }) {
            const placePickerStores = yield call(PlacePickerStoreFacade.getPlacePickerStores, payload);
            yield put(actions.successGetPlacePickerStore(placePickerStores.value));
        });
    } catch (error) {
        yield put(actions.errorGetPlacePickerStore({ error }));
    }
}

export default function* rootSaga() {
    yield all([fork(getPlacePickerStore)]);
}
