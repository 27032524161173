const constants = {
    SET_STORES_BY_MERCHANT_ID: 'SET_STORES_BY_MERCHANT_ID',
    GET_STORES_BY_MERCHANT_ID: 'GET_STORES_BY_MERCHANT_ID',
    SET_FILTERED_STORES: 'SET_FILTERED_STORES',
    SET_CLOSEST_STORE: 'SET_CLOSEST_STORE',
    SET_VISIBLE_STORES: 'SET_VISIBLE_STORES',
};

const actions = {
    setFilteredStores: (payload) => ({
        type: constants.SET_FILTERED_STORES,
        payload,
    }),
    getStoresByMerchantId: (payload) => ({
        type: constants.GET_STORES_BY_MERCHANT_ID,
        payload,
    }),
    setClosestStore: (payload) => ({
        type: constants.SET_CLOSEST_STORE,
        payload,
    }),
    setVisibleStores: (payload) => ({
        type: constants.SET_VISIBLE_STORES,
        payload,
    }),
};

export default { ...constants, ...actions };
