import styled from 'styled-components';

export const Logo = styled.div`
    margin-left: auto;
    margin-right: auto;
    width: ${({ theme }) => theme.logoWidth || '155px'};

    img {
        width: 100%;
    }
`;
