import axios from 'axios/index';

const { REACT_APP_SERVER } = process.env;

export class PlacePickerStoreFacade {
    static async getPlacePickerStores(data) {
        return axios
            .get(`${REACT_APP_SERVER}/api/merchants/${data.merchantId}/stores`)
            .then(res => res.data)
            .catch(err => console.log(err));
    }
}
