import axios from 'axios/index';

const { REACT_APP_SERVER } = process.env;

export class MerchantFacade {
    static async getMerchantByUrl(data) {
        return axios
            .get(`${REACT_APP_SERVER}/api/merchant/${data.locationName}`)
            .then((res) => res.data)
            .catch((err) => console.log(err));
    }

    static async getTemplateByMerchantId(data) {
        return axios
            .get(`${REACT_APP_SERVER}/api/merchant/template/${data.locationName}`)
            .then((res) => res.data)
            .catch((err) => console.log(err));
    }

    static async getApiKeys() {
        return axios
            .get(`${REACT_APP_SERVER}/api/keys/`)
            .then((res) => res.data)
            .catch((err) => console.log(err));
    }
}
