import axios from 'axios/index';

const { REACT_APP_SERVER } = process.env;

export class StoreFacade {
    static async getStoresByMerchantId(data) {
        return axios
            .get(`${REACT_APP_SERVER}/api/stores/${data.merchantId}`)
            .then((res) => res.data)
            .catch((err) => console.log(err));
    }
}
