import 'reflect-metadata';
import '@babel/polyfill';
import React from 'react';
import { render } from 'react-dom';
import Root from './containers/Root';
import './styles.css';
import './fonts.css';

const target = document.querySelector('#root');

render(<Root />, target);
