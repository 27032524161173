import React, { Component } from 'react';
import { Layout } from 'antd';
import { connect } from 'react-redux';
import Loadable from 'react-loadable';
import merge from 'lodash/merge';
import debounce from 'lodash/debounce';
import { ThemeProvider, injectGlobal } from 'styled-components';
import 'antd/es/layout/style/css';
import { LoadScript } from '@react-google-maps/api';
import Spinner from '../../components/Spinner/Spinner';
import HeaderLogo from '../../components/HeaderLogo/HeaderLogo';
import merchantActions from '../../redux/merchant/actions';
import storesActions from '../../redux/stores/actions';
import placePickerStoreActions from '../../redux/placePickerStore/actions';
import mapActions from '../../redux/map/actions';
import themes from '../../themes';
import { Header, Body, Container, Sidebar, HomeButton } from '../../components/shared';
import { Logo } from './styles';
import StoreBanner from '../../components/Map/mapBanner';

import './styles.css';

const { Content } = Layout;
const Map = Loadable({
    loader: () => import('../../components/Map/Map'),
    loading() {
        return <React.Fragment />;
    },
});
const LeftContainer = Loadable({
    loader: () => import('../LeftContainer/LeftContainer'),
    loading() {
        return <React.Fragment />;
    },
});

class MainContainer extends Component {
    state = {
        merchantStore: null,
        stores: null,
        mapDetails: null,
        source: null,
        width: window.innerWidth,
    };
    libraries = ['geometry', 'drawing', 'places'];

    UNSAFE_componentWillReceiveProps(props) {
        const { getStores, getPlacePickerStores } = this.props;

        if (props.merchantStore && !props.placePickerStores) {
            getPlacePickerStores({ merchantId: props.merchantStore.merchantId });
        }

        if (props.merchantStore && !this.state.merchantStore && !this.state.stores) {
            this.setState({ merchantStore: props.merchantStore, mapDetails: props.merchantStore.metadata.mapDetails });
            getStores({ merchantId: props.merchantStore.merchantId, tags: props.location.search });
        }

        if (props.filteredStores && props.filteredStores.length) {
            this.setState({ stores: props.filteredStores });
        }
    }

    componentDidMount() {
        const { locationName, source } = this.props.match.params;
        this.setState({ source });
        const { merchantStore } = this.state;
        const { getMerchant, getTemplate, template } = this.props;

        window.addEventListener('resize', this.handleSizeChange);

        if (!template) {
            getTemplate({ locationName });
        }

        if (!merchantStore) {
            getMerchant({ locationName });
        }
    }

    handleSizeChange = debounce(() => {
        this.setState({
            width: window.innerWidth,
        });
    }, 100);

    setLocation = (storeId) => {
        this.props.setSelectedLocation({
            storeId,
        });
    };

    resetMapDetails = () => {
        const { resetMapDetails, merchantStore, setSearchBoxValue } = this.props;
        resetMapDetails(merchantStore.metadata.mapDetails);
        setSearchBoxValue('');
    };

    render() {
        const { merchantStore, stores, mapDetails } = this.state;
        const { googleApiKey, template, match, selectedLocation } = this.props;
        if (template && Array.isArray(template.fontSources)) {
            let fontFace = '';
            template.fontSources.forEach((font) => {
                fontFace += `@font-face {
          font-family: "${font.name}";
          src: url(${font.src}) format('${font.format}');
          ${font.weight ? 'font-weight:' : ''} ${font.weight ? font.weight : ''};
          ${font.style ? 'font-style:' : ''} ${font.style ? font.style : ''};
        }`;
            });
            injectGlobal`
         ${fontFace}`;
        }

        if (!googleApiKey || !merchantStore || !template) {
            return (
                <div className="loading-container">
                    <Spinner isLoading />
                </div>
            );
        }

        const theme = merge(themes.default, template);
        //const width = window.innerWidth;
        const { homeURL, homeURLText, headerLogo } = theme;
        const Home =
            match.params.source === 'webstore' && homeURL ? (
                <HomeButton href={homeURL} target="_top">
                    {homeURLText || 'HOME'}
                </HomeButton>
            ) : null;

        if (this.state.width < 796) {
            return (
                <ThemeProvider theme={theme}>
                    <Body style={{ minHeight: '100vh' }}>
                        <Header className="header">
                            {Home}
                            <Logo>
                                <HeaderLogo headerLogo={headerLogo} merchantStore={merchantStore} />
                            </Logo>
                        </Header>
                        <Layout>
                            <LoadScript id="loader-script" libraries={this.libraries} googleMapsApiKey={this.props.googleApiKey}>
                                <div style={{ height: '200px' }}>
                                    {theme.banner && theme.banner.enabled && theme.banner.enabled.mobile && (
                                        <StoreBanner selectedLocation={selectedLocation} theme={theme} isMobile />
                                    )}
                                    {mapDetails && stores && <Map mapDetails={mapDetails} setLocation={this.setLocation} theme={theme} />}
                                </div>
                                <LeftContainer resetMapDetails={this.resetMapDetails} setLocation={this.setLocation} theme={theme} />
                            </LoadScript>
                        </Layout>
                    </Body>
                </ThemeProvider>
            );
        }
        return (
            <ThemeProvider theme={theme}>
                <Body style={{ height: '100vh' }}>
                    <Header className="header">
                        {Home}
                        <Logo>
                            <HeaderLogo headerLogo={headerLogo} merchantStore={merchantStore} />
                        </Logo>
                    </Header>
                    <Layout>
                        <LoadScript libraries={this.libraries} googleMapsApiKey={this.props.googleApiKey} id="loader-script">
                            <Sidebar
                                width={550}
                                collapsedWidth={350}
                                breakpoint={'lg'}
                                style={{
                                    background: theme.sidebar.backgroundColor || 'whitesmoke',
                                    height: '100vh',
                                    overflow: 'auto',
                                    position: 'fixed',
                                }}
                            >
                                <LeftContainer resetMapDetails={this.resetMapDetails} setLocation={this.setLocation} theme={theme} />
                            </Sidebar>
                            <Container className="layout-map">
                                <Content className="content">
                                    {theme.banner && theme.banner.enabled && theme.banner.enabled.desktop && (
                                        <StoreBanner selectedLocation={selectedLocation} theme={theme} />
                                    )}
                                    {mapDetails && stores && <Map mapDetails={mapDetails} setLocation={this.setLocation} theme={theme} />}
                                </Content>
                            </Container>
                        </LoadScript>
                    </Layout>
                </Body>
            </ThemeProvider>
        );
    }
}

export default connect(
    (state) => ({
        googleApiKey: state.Map.get('googleApiKey'),
        selectedLocation: state.Map.get('selectedLocation'),
        merchantStore: state.Merchant.get('merchantStore'),
        template: state.Merchant.get('template'),
        stores: state.Stores.get('stores'),
        filteredStores: state.Stores.get('filteredStores'),
        gTag: state.Merchant.get('gTag'),
        placePickerStores: state.PlacePickerStore.get('stores'),
    }),
    {
        getMerchant: merchantActions.getStoresByMerchantId,
        getTemplate: merchantActions.getTemplateByMerchantId,
        getStores: storesActions.getStoresByMerchantId,
        setSelectedLocation: mapActions.setSelectedLocation,
        resetMapDetails: mapActions.resetMapDetails,
        setSearchBoxValue: mapActions.setSearchBoxValue,
        getPlacePickerStores: placePickerStoreActions.requestGetPlacePickerStore,
    },
)(MainContainer);
