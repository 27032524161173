import Stores from './stores/reducers';
import Merchant from './merchant/reducers';
import Map from './map/reducers';
import PlacePickerStore from './placePickerStore/reducers';

export default {
    Stores,
    Merchant,
    Map,
    PlacePickerStore
};
