const constants = {
    SET_MAP_DETAILS: 'SET_MAP_DETAILS',
    SET_SHOW_LIVE_MAP: 'SET_SHOW_LIVE_MAP',
    SET_SELECTED_LOCATION: 'SET_SELECTED_LOCATION',
    SET_LOCATION: 'SET_LOCATION',
    SET_SEARCHBOX_VALUE: 'SET_SEARCHBOX_VALUE',
    RESET_MAP_DETAILS: 'RESET_MAP_DETAILS',
    SET_FILTER_STATUS: 'SET_FILTER_STATUS',
    RESET_ALL_LOCATIONS: 'RESET_ALL_LOCATIONS',
};

const actions = {
    setSelectedLocation: (payload) => ({
        type: constants.SET_SELECTED_LOCATION,
        payload,
    }),
    setShowLiveMap: (payload) => ({
        type: constants.SET_SHOW_LIVE_MAP,
        payload,
    }),
    setLocation: (payload) => ({
        type: constants.SET_LOCATION,
        payload,
    }),
    setMapDetails: (payload) => ({
        type: constants.SET_MAP_DETAILS,
        payload,
    }),
    setSearchBoxValue: (payload) => ({
        type: constants.SET_SEARCHBOX_VALUE,
        payload,
    }),
    resetMapDetails: (payload) => ({
        type: constants.RESET_MAP_DETAILS,
        payload,
    }),
    resetAllLocations: (payload) => ({
        type: constants.RESET_ALL_LOCATIONS,
        payload,
    }),
    setFilterStatus: (payload) => ({
        type: constants.SET_FILTER_STATUS,
        payload,
    }),
};

export default { ...actions, ...constants };
