import { hot } from 'react-hot-loader';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Route } from 'react-router-dom';
import { ConnectedRouter } from 'react-router-redux';
import { store, history } from '../redux/store/configureStore';
import MainContainer from './MainContainer/MainContainer';
import Boot from '../redux/boot';

const Root = hot(module)(() => (
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <BrowserRouter>
                <Route path="/locations/:locationName/:source/" component={MainContainer} />
            </BrowserRouter>
        </ConnectedRouter>
    </Provider>
));
Boot()
    .then(() => Root())
    .catch((error) => console.error(error));

export default Root;
