import { Map } from 'immutable';
import actions from './actions';
import merchantActions from '../merchant/actions';

const initState = new Map({
    googleApiKey: null,
    showDynamicMap: false,
    mapDetailsFromStore: null,
    searchBoxValue: '',
    selectedLocation: null,
    filterStatus: 'all',
    resetAllLocations: false,
});

export default function mapReducer(state = initState, action) {
    switch (action.type) {
        case merchantActions.SET_API_KEYS:
            return state.set('googleApiKey', action.payload.GOOGLE_API_KEY);
        case actions.SET_SHOW_LIVE_MAP:
            return state.set('showDynamicMap', action.payload.showDynamicMap);
        case actions.SET_MAP_DETAILS:
            return state.set('mapDetailsFromStore', action.payload);
        case actions.SET_SEARCHBOX_VALUE:
            return state.set('searchBoxValue', action.payload);
        case actions.RESET_MAP_DETAILS:
            return state
                .set('searchBoxValue', '')
                .set('selectedLocation', null)
                .set('mapDetailsFromStore', { ...action.payload });
        case actions.SET_LOCATION:
            return state.set('selectedLocation', action.payload);
        case actions.RESET_ALL_LOCATIONS:
            return state.set('resetAllLocations', action.payload);
        case actions.SET_FILTER_STATUS:
            return state.set('filterStatus', action.payload);
        default:
            return state;
    }
}
