import React from 'react';
import { Banner, BannerMobile } from './styles';

const StoreBanner = (props) => {
    const { isMobile, selectedLocation, theme } = props;
    const { banner } = theme;

    const isBannerReady = !isMobile && banner && banner.imgUrl && banner.imgUrl.desktop;
    const isMobileBannerReady = isMobile && banner && banner.imgUrl && banner.imgUrl.mobile;
    if (isBannerReady && (!selectedLocation || banner.alwaysOnDisplay.desktop)) {
        return <Banner />;
    } else if (isMobileBannerReady && (!selectedLocation || banner.alwaysOnDisplay.mobile)) {
        return <BannerMobile />;
    } else {
        return null;
    }
};

export default StoreBanner;
